import React from "react";
import { Page } from "components/Page";
import { FilteredTicketshop } from "components/FilteredTicketshop";

const EventsPage = () => {
  return (
    <Page>
      {/* <div className="relative">
        <span
          className="absolute opacity-40 inset-0 w-full h-full"
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.42) 5.45%, rgba(0, 0, 0, 0) 25.31%, rgba(0, 0, 0, 0) 70.71%, rgba(0, 0, 0, 0.4) 95.6%)",
          }}
        ></span>
        <MotionImage
          className="w-full object-cover"
          style={{ maxHeight: 800, aspectRatio: 1400 / 761 }}
          src="/hero-image.jpg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        />
      </div> */}
      <div className="container mx-auto mt-16">
        <div>
          <FilteredTicketshop id="f8e48caf-038f-4062-b6b5-e963e15a7183" />
        </div>
        <div className="grid sm:grid-cols-2 gap-8">
          {/* <Link to="/tickets/bobbys-disco-club">
            <Tile
              backgroundUrl="/bobbys-hero.jpg"
              imageUrl="/logo-bobbys.png"
              delay={0.1}
            />
          </Link>
          <Link to="/tickets/mixtape-project">
            <Tile
              backgroundUrl="/mixtape-hero.jpg"
              imageUrl="/logo-mixtape.png"
              delay={0.2}
            />
          </Link>
          <Link to="/tickets/sticky-feet">
            <Tile
              backgroundUrl="/sticky-feet-hero.jpg"
              imageUrl="/logo-sticky-feet.png"
              delay={0.3}
            />
          </Link>
          <Link to="/tickets/just-a-little">
            <Tile
              backgroundUrl="/just-a-little-hero.jpg"
              imageUrl="/logo-jal.png"
              delay={0.4}
            />
          </Link>
          <Link to="/tickets/deep-fried-funk">
            <Tile
              backgroundUrl="/dff-hero.jpg"
              imageUrl="/logo-dff.png"
              delay={0.5}
            />
          </Link>
          <Link to="/tickets/panda-riot">
            <Tile
              backgroundUrl="/hero/Panda Riot.jpg"
              imageUrl="/logo/Panda Riot.png"
              delay={0.5}
            />
          </Link> */}
        </div>
      </div>
    </Page>
  );
};

export default EventsPage;
